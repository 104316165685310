<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col>
            <b-input-group>
              <b-form-input
                v-model="search"
                placeholder="Müşteri Adı, Araç Plakası..."
                @keydown.enter="searchData"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="searchData"
                >
                  <FeatherIcon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="auto">
            <b-button
              to="swapcars/search"
              variant="primary"
            >
              <FeatherIcon icon="PlusIcon" />Oluştur
            </b-button>
          </b-col>
        </b-row>

        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(customer)="data">
            {{ data.item.customer }}
            <div v-if="data.item.company_name">
              <small class="text-primary">{{ data.item.company_name }}</small>
            </div>
          </template>
          <template #cell(swap_brand)="data">
            <div>
              {{ data.item.swap_brand }} {{ data.item.swap_model }}
            </div>
            <div v-if="data.item.hardware">
              <small class="text-primary">
                {{ data.item.hardware }} {{ data.item.model_year }}
              </small>
            </div>
            <div v-if="data.item.license_plate">
              <small class="text-warning">
                {{ data.item.license_plate }}
              </small>
            </div>
          </template>
          <template #cell(username)="data">
            {{ data.item.username }}
            <div v-if="data.item.edate">
              <small class="text-warning">{{ (data.item.edate)? moment(data.item.edate).format('DD.MM.YYYY') : '-' }}</small>
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              :to="'/swapcars/view/' + data.item.id"
              variant="outline-primary"
              size="sm"
            >
              Görüntüle
            </b-button>
          </template>
        </b-table>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
        },
        {
          key: 'swap_brand',
          label: 'Araç',
        },
        {
          key: 'username',
          label: 'Eksper',
          thStyle: { width: '200px' },
        },
        {
          key: 'username',
          label: 'Eksper',
          thStyle: { width: '200px' },
        },
        {
          key: 'user_request',
          label: 'Talep Eden',
          thStyle: { width: '200px' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
        },
      ],
      dataQuery: {
        select: [
          'com_swapcar.id AS id',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_swap_brand.name AS swap_brand',
          'com_swap_model.title AS swap_model',
          'com_swapcar.edate AS edate',
          'com_swapcar.hardware AS hardware',
          'com_swapcar.license_plate AS license_plate',
          'com_swapcar.model_year AS model_year',
          'com_swapcar.id_com_swap_brand AS id_com_swap_brand',
          'com_swapcar.id_com_swap_model AS id_com_swap_model',
          'com_user.name AS username',
          'user_request.name AS user_request',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['swapcars/getSwapcars']
    },
    dataCount() {
      return this.$store.getters['swapcars/getSwapcarsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    searchData() {
      this.dataQuery.or_like = {
        'com_customer.name': this.search,
        'com_customer.company_name': this.search,
        'com_customer.phone': this.search,
        'com_swapcar.license_plate': this.search,
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('swapcars/swapcarsList', this.dataQuery)
    },
  },
}
</script>

<style scoped>

</style>
